import React from 'react'
import { Menu, Spin } from 'antd'
import { NavLink, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { menuList } from './menuList'

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch()
  const { profile, loading } = useSelector((state) => state.profile)

  const pathName = window.location.pathname
  const pathArray = pathName.split(path)
  const mainPath = pathArray[1]
  const mainPathSplit = mainPath.split('/')
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []
  )

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys)
  }

  const onClick = (item) => {
    console.log(item)
    if (item.keyPath.length === 1) setOpenKeys([])
  }

  console.log(profile)
  if (loading) return <Spin />
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {menuList
        .filter((i) => profile?.permissions.includes(i.key))
        .map((menu) => (
          <Menu.Item
            key={menu.key}
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}${menu.path}`}>
                  <FeatherIcon icon={menu.icon} />
                </NavLink>
              )
            }
          >
            <NavLink onClick={toggleCollapsed} to={`${path}${menu.path}`}>
              {menu.name}
            </NavLink>
          </Menu.Item>
        ))}
    </Menu>
  )
}

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func
}

export default MenuItems
