import axios from 'axios'
import { message } from 'antd'
import { getItem, removeItem } from '../../utility/localStorageControl'

const API_ENDPOINT = `${process.env.REACT_APP_BASE_URL}/api/v1`

export const ACCESS_TOKEN = 'access_token'

const client = axios.create({
  baseURL: API_ENDPOINT
})

class DataService {
  static get(path = '', params = {}) {
    return client({ method: 'GET', url: path, params })
  }

  static post(path = '', data = {}, params) {
    return client({ method: 'POST', url: path, data, params })
  }

  static patch(path = '', data = {}, params) {
    return client({ method: 'PATCH', url: path, data, params })
  }

  static delete(path = '', data = {}, params) {
    return client({ method: 'DELETE', url: path, data, params })
  }

  static put(path = '', data = {}, params) {
    return client({ method: 'PUT', url: path, data, params })
  }
}

client.interceptors.request.use((config) => {
  const token = getItem(ACCESS_TOKEN)
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`
    }
  }
  return config
})

client.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      console.error('API Error:', error.response)
      if (error.response.status === 401) {
        message.error('Tizimga qayta kiring')
        removeItem(ACCESS_TOKEN)
        window.location.href = '/login'
      }
      if (error.response.status === 500) {
        console.log('Server xatosi: 500')
      }
    } else {
      console.error('Network yoki boshqa xato:', error)
    }
    return Promise.reject(error)
  }
)

export { DataService }
