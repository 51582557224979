import actions from './actions'
import { getItem } from '../../utility/localStorageControl'
import { ACCESS_TOKEN } from '../../config/dataService/dataService'

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR } = actions

const initState = {
  login: getItem(ACCESS_TOKEN),
  loading: false,
  error: null
}

const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action
  switch (type) {
    case LOGIN_BEGIN:
      return { ...state, loading: true }
    case LOGIN_SUCCESS:
      return { ...state, login: data, loading: false }
    case LOGIN_ERR:
      return { ...state, error: err, loading: false }
    case LOGOUT_BEGIN:
      return { ...state, loading: true }
    case LOGOUT_SUCCESS:
      return { ...state, login: data, loading: false }
    case LOGOUT_ERR:
      return { ...state, error: err, loading: false }
    default:
      return state
  }
}
export default AuthReducer
