import React, { useEffect } from 'react'
import { Avatar, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FeatherIcon from 'feather-icons-react'
import { InfoWraper, UserDropDwon } from './auth-info-style'
import { Popover } from '../../popup/popup'
import { logOut } from '../../../redux/authentication/actionCreator'
import Heading from '../../heading/heading'
import { fetchProfile } from '../../../redux/profile/actions'

function AuthInfo() {
  const dispatch = useDispatch()
  const { profile, loading } = useSelector((state) => state.profile)

  useEffect(() => {
    dispatch(fetchProfile())
  }, [dispatch])

  const SignOut = (e) => {
    e.preventDefault()
    dispatch(logOut())
  }

  const userContent = profile ? (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img
            src={profile?.image || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'}
            style={{ maxWidth: '50px', height: '50px' }}
            alt=""
          />
          <figcaption>
            <Heading as="h5">{profile?.fullName}</Heading>
            <p>ADMIN</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/profile/edit/info">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  ) : null

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={loading ? <Spin size="small" /> : userContent} action="click">
          <Link to="#" className="head-example">
            {loading ? (
              <Spin size="small" />
            ) : (
              <Avatar
                src={profile?.image || 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'}
              />
            )}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  )
}

export default AuthInfo
