import { Spin } from 'antd'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import withAdminLayout from '../layout/withAdminLayout'

const Leads = lazy(() => import('../container/leads/Leads'))
const Doctors = lazy(() => import('../container/doctors/Doctors'))
const DoctorCreate = lazy(() => import('../container/doctors/DoctorCreate'))
const Employees = lazy(() => import('../container/employees/Employees'))
const EmployeeCreate = lazy(() => import('../container/employees/EmployeeCreate'))
const Rooms = lazy(() => import('../container/rooms/Rooms'))
const Patients = lazy(() => import('../container/patients/Patients'))
const Dashboard = lazy(() => import('../container/dashboard/Dashboard'))
const Profile = lazy(() => import('../container/profile/Profile'))
const ProfileEditPassword = lazy(() => import('../container/profile/ProfileEdtPassword'))
const ProfileEditImage = lazy(() => import('../container/profile/ProfileEditImage'))
const Permission = lazy(() => import('../container/permission/Permission'))
const Role = lazy(() => import('../container/role/Role'))

const Admin = () => {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/admin" component={Dashboard} />
        <Route exact path="/admin/leads" component={Leads} />
        <Route exact path="/admin/patients" component={Patients} />
        <Route exact path="/admin/doctors" component={Doctors} />
        <Route exact path="/admin/doctors/:id" component={DoctorCreate} />
        <Route exact path="/admin/employees" component={Employees} />
        <Route exact path="/admin/employee/create" component={EmployeeCreate} />
        <Route exact path="/admin/employees/:id" component={EmployeeCreate} />
        <Route exact path="/admin/profile/edit/info" component={Profile} />
        <Route exact path="/admin/profile/edit/image" component={ProfileEditImage} />
        <Route exact path="/admin/profile/edit/password" component={ProfileEditPassword} />
        <Route exact path="/admin/rooms" component={Rooms} />
        <Route exact path="/admin/roles" component={Role} />
        <Route exact path="/admin/perm" component={Permission} />
      </Suspense>
    </Switch>
  )
}

export default withAdminLayout(Admin)
