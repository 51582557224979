import actions from './actions'
import { ACCESS_TOKEN, DataService } from '../../config/dataService/dataService'
import { setItem, removeItem } from '../../utility/localStorageControl'

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions

const login = (e) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin())
      const data = await DataService.post('/api/v1/auth/login', e)
      setItem(ACCESS_TOKEN, data?.accessToken)
      dispatch(loginSuccess(true))
    } catch (err) {
      dispatch(loginErr(err))
    }
  }
}

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin())
      removeItem(ACCESS_TOKEN)
      dispatch(logoutSuccess(null))
    } catch (err) {
      dispatch(logoutErr(err))
    }
  }
}

export { login, logOut }
