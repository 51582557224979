import {
    FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE
} from './types';

import { DataService } from '../../config/dataService/dataService';
import { getProfile, updateProfile as apiUpdateProfile, changeProfilePassword as apiChangePassword } from '../../config/api/profile';

export const fetchProfile = () => async (dispatch) => {
    dispatch({ type: FETCH_PROFILE_REQUEST });
    try {
        const response = await getProfile();
        if (response.success) {
            dispatch({ type: FETCH_PROFILE_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: FETCH_PROFILE_FAILURE, payload: 'Error fetching profile' });
        }
    } catch (error) {
        dispatch({ type: FETCH_PROFILE_FAILURE, payload: error.message });
    }
};

export const updateProfile = (profileData) => async (dispatch) => {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    try {
        const response = await apiUpdateProfile(profileData);
        if (response.success) {
            dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: UPDATE_PROFILE_FAILURE, payload: 'Error updating profile' });
        }
    } catch (error) {
        dispatch({ type: UPDATE_PROFILE_FAILURE, payload: error.message });
    }
};

export const changeProfilePassword = (passwordData) => async (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    try {
        const response = await apiChangePassword(passwordData);
        if (response.success) {
            dispatch({ type: CHANGE_PASSWORD_SUCCESS });
        } else {
            dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: 'Error changing password' });
        }
    } catch (error) {
        dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
    }
};

export const uploadImage = (file) => async (dispatch) => {
    dispatch({ type: UPLOAD_IMAGE_REQUEST });
    const formData = new FormData();
    formData.append('image', file);
    try {
        const response = await DataService.put('/auth/profile/change-image', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.data.success) {
            dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: response.data });
        } else {
            dispatch({ type: UPLOAD_IMAGE_FAILURE, payload: 'Error uploading image' });
        }
    } catch (error) {
        dispatch({ type: UPLOAD_IMAGE_FAILURE, payload: error.message });
    }
};