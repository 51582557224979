import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthLayout from '../container/auth/index'

const Login = lazy(() => import('../container/auth/Login'))

function NotFound() {
  return <Redirect to="/login" />
}

function FrontendRoutes() {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/login" component={Login} />
        <Route exact path="*" component={NotFound} />
      </Suspense>
    </Switch>
  )
}

export default AuthLayout(FrontendRoutes)
