import {
    FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS, FETCH_PROFILE_FAILURE,
    UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    UPLOAD_IMAGE_REQUEST, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILURE
} from './types';

const initialState = {
    profile: null,
    loading: false,
    error: null,
    image: '',
    passwordChangeSuccess: false,
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROFILE_REQUEST:
        case UPDATE_PROFILE_REQUEST:
        case CHANGE_PASSWORD_REQUEST:
        case UPLOAD_IMAGE_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, image: action.payload.image, loading: false };

        case UPDATE_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, image: action.payload.image, loading: false };

        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, passwordChangeSuccess: true, loading: false };

        case UPLOAD_IMAGE_SUCCESS:
            return { ...state, profile: { ...state.profile, image: action.payload.image }, loading: false };

        case FETCH_PROFILE_FAILURE:
        case UPDATE_PROFILE_FAILURE:
        case CHANGE_PASSWORD_FAILURE:
        case UPLOAD_IMAGE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default profileReducer;
